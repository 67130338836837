import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import SceneObject from '../types/SceneObject';
import * as THREE from 'three';
import {Mesh} from 'three';
import {HTTP} from "../lib/axios";
import {MaterialObject} from '../types';


class CreateThreeObjects {

    static createThreeObjectFromSceneObject(sceneObject: SceneObject): THREE.Mesh | Promise<GLTF> | Promise<THREE.Mesh> {
        switch (sceneObject.objectType) {
            case 'Cube':
                return this.createThreeCube(sceneObject);
            case 'Sphere':
                return this.createThreeSphere(sceneObject);
            case 'Text':
                return this.createThreeJsTextObject(sceneObject);
            case 'GLTF':
                return this.createThreeObjectFromGLTF(sceneObject);
            default:
                throw new Error("Wrong type");
        }
    }

    static createThreeCube(sceneObject: SceneObject): THREE.Mesh {
        console.debug(`Creating Cube with backend Id: ${sceneObject.id}`);
        const dimension = sceneObject.spatialInformation.size;
        const position = sceneObject.spatialInformation.position;

        const geometry = new THREE.BoxGeometry(dimension?.height, dimension?.width, dimension?.depth);
        const material = new THREE.MeshBasicMaterial({color: parseInt(sceneObject.objectInformation.color as string, 16)});
        const cube = new THREE.Mesh(geometry, material);
        cube.position.set(position.x, position.y, position.z);
        return cube
    }

    static async createThreeJsTextObject(sceneObject: SceneObject): Promise<THREE.Mesh> {
        const {position, fontsize = 1, rotation} = sceneObject.spatialInformation;
        let text = sceneObject.objectInformation.text;
        if (!text) {
            text = 'Default'
        }
        const textMesh = await this.loadTextGeometry(+fontsize, text);
        textMesh.computeBoundingBox();
        textMesh.computeVertexNormals();
        const material = new THREE.MeshPhongMaterial({color: 0xffffff, flatShading: true});
        const mesh = new THREE.Mesh(textMesh, material);
        mesh.position.set(position.x, position.y, position.z);
        if (rotation) {
            mesh.rotation.set(rotation.x, rotation.y, rotation.z);
        }
        return mesh

    }

    static loadTextGeometry(size: number, text: string): Promise<THREE.TextGeometry> {
        const loader = new THREE.FontLoader();
        return new Promise((resolve) => {
            loader.load('fonts/helvetiker_regular.typeface.json', function (font) {
                const geometry = new THREE.TextGeometry(text, {
                    font: font,
                    size: size,
                    height: 0.01,
                    curveSegments: 16,
                    bevelEnabled: false
                });
                resolve(geometry);
            });
        });
    }

    static createThreeSphere(sceneObject: SceneObject): THREE.Mesh {
        console.debug(`Creating Spehere with backend Id: ${sceneObject.id}`);
        const sizes = sceneObject.spatialInformation.size;
        const position = sceneObject.spatialInformation.position;
        const width = sizes ? sizes.width : 1;
        const geometry = new THREE.SphereGeometry(width);
        const material = new THREE.MeshBasicMaterial({color: parseInt(sceneObject.objectInformation.color as string, 16)});
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.set(position.x, position.y, position.z);
        return sphere
    }

    static createThreeObjectFromGLTF(sceneObject: SceneObject): Promise<GLTF> {
        console.debug(`Creating Model from GLTF with backend Id: ${sceneObject.id}`);
        const loader = new GLTFLoader();
        return new Promise((resolve, reject) => {
            loader.load(`${process.env.REACT_APP_API_URL}/gltfModels/${sceneObject.gltfModelId}`, async (gltf) => {
                const {position, size, rotation} = sceneObject.spatialInformation;
                gltf.scene.position.set(position.x, position.y, position.z);
                const {width, height, depth} = size ? size : {width: 1, height: 1, depth: 1};
                gltf.scene.scale.set(width, height, depth);
                if(rotation) {
                    gltf.scene.rotation.set(rotation.x, rotation.y, rotation.z);
                }
                if(sceneObject.objectInformation?.materialId) {
                    const materialRequest = await HTTP.get(`/materials/${sceneObject.objectInformation.materialId}`)
                    if(materialRequest) {
                        const materialObject: MaterialObject = materialRequest.data.data;
                        const materialInformation  = materialObject?.materialInformation;
                        if(materialInformation) {
                            gltf.scene?.traverse(async childs => {
                                if (childs.type === "Mesh") {
                                    const mesh = childs as THREE.Mesh;
                                    mesh.material = new THREE.MeshLambertMaterial({color: materialInformation.color, reflectivity: materialInformation.reflectivity});
                                }
                            });
                        }
                    }
                }
                resolve(gltf)
            },
              () => {},
              (error: any) => reject(error))
        }
        );
    }
}

export default CreateThreeObjects;