import React, {useEffect, useState} from "react";
import './button.css'

interface Props {
    isDisabled?: boolean
    isVisible?: boolean
    onClick?: () => {}
    children?: React.ReactNode
}

const ControlledButton = (props: Props) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const isVisible = props.isVisible === undefined ? true : props.isVisible;
        setIsVisible(isVisible);
    }, [props.isVisible]);

    useEffect(() => {
        const isDisabled = props.isDisabled === undefined ? true : props.isDisabled;
        setIsDisabled(isDisabled);
    }, [props.isDisabled]);

    return (
        <button className={isVisible ? 'button' : 'button-invisible'}
                disabled={isDisabled}
                onClick={props.onClick ? props.onClick : () => {} }
        >
            {props.children}
        </button>
    )
};

export default ControlledButton