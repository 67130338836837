import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import MixerInfo from '../../types/MixerInfo'
import { AnimationMaterials } from './animationMaterials';
import { MaterialObject, SceneObject } from '../../types';
import { HTTP } from '../axios';

class AnimMixer {
    currentAnimationOfObjects: Map<string, string> = new Map();
    mixerInfoMap: Map<string, MixerInfo> = new Map();
    clock = new THREE.Clock();
    deltaSeconds = this.clock.getDelta();
    animationMaterials = new AnimationMaterials();

    initialize = (gltf: GLTF) => {
        const mixer = new THREE.AnimationMixer( gltf.scene );
        const actions = this.createAnimationMapForSpecificGLTF(gltf, mixer)
        this.mixerInfoMap.set( gltf.scene.name, {
            mixer, 
            actions,
            scene: gltf.scene
        }) 
    }
    createAnimationMapForSpecificGLTF =  (gltf: GLTF, mixer: THREE.AnimationMixer): Map<string, THREE.AnimationAction> => {
        const clipActionArray = new Map()
        gltf.animations.forEach(animation => {
            clipActionArray.set(animation.name, mixer.clipAction(animation));
        });
        return clipActionArray;
    }
    startAnimation = async (objectId: string, animationName: string) => {
        const mixerInfo = this.mixerInfoMap.get(objectId);
        const action = mixerInfo?.actions.get(animationName);
        action?.setLoop(THREE.LoopRepeat, Infinity);
        action?.play()
        this.currentAnimationOfObjects.set(objectId, animationName)
        
    }
    changeAnimation = async (objectId: string, animationName: string) => {
        const mixerInfo = this.mixerInfoMap.get(objectId);
        if(mixerInfo) {
            const action = mixerInfo.actions.get(animationName);
            const currentActionName = this.currentAnimationOfObjects.get(objectId);
            const scene = mixerInfo.scene
            if(currentActionName && action && scene) {
                const currentAction =  mixerInfo?.actions.get(currentActionName);
                if(currentActionName !== animationName && currentAction) {
                    action.stop();
                    currentAction.fadeOut(1);
                    action.fadeIn(1);
                    action.play();
                    this.changeMaterialOnGLTF(scene)
                }
                this.currentAnimationOfObjects.set(objectId, animationName);
            }
        }
    }      
    getMixerInfoArray = () => {
        return this.mixerInfoMap;
    }
    
    private changeMaterialOnGLTF = async (scene: THREE.Scene) => {
        try {
            const sceneObject: SceneObject = await HTTP.get(`/scene/objects/${scene.name}`)
            if(sceneObject.objectInformation.materialId) {
                const material = await HTTP.get(`/scene/objects/${scene.name}/objectInformation/material`)
                if(material.data.data) {
                    const materialId: string = material.data.data
                    const materialRequest = await HTTP.get(`/materials/${materialId}`)
                    if(materialRequest) {
                        const materialObject: MaterialObject = materialRequest.data.data;
                        const materialInformation  = materialObject?.materialInformation;
                        if(materialInformation) {
                            scene?.traverse(async (childs) => {
                                if (childs.type === "Mesh") {
                                    const mesh = childs as THREE.Mesh;
                                    mesh.material = new THREE.MeshLambertMaterial({color: materialInformation.color, reflectivity: materialInformation.reflectivity});
                                }
                            });
                        }
                    }
                }
            }
        } catch(err) {
            return err
        }
    }
}


    export default AnimMixer;

