/* 
    DEPRECATED
*/
import * as THREE from 'three';

export class AnimationMaterials {
    
    getMaterialForAnimation = ( animationName: string ) => {
        switch(animationName) {
        case "idle":
            return this.createIdleMaterial();
        case "ready":
            return this.createReadyMaterial();
        case "maintenance":
            return this.createMaintenanceMaterial();
        case "somethingElse":
            break;
        default:
            break;
        }
    }

    private createIdleMaterial = (): THREE.MeshPhysicalMaterial => {
        return new THREE.MeshPhysicalMaterial({color: 0xf90101, roughness: 0.6, metalness: 1, reflectivity: 0.5, clearCoat: 0.52, emissive: 0x140007});
    }

    private createReadyMaterial = (): THREE.MeshPhysicalMaterial => {
        return new THREE.MeshPhysicalMaterial({color: 0x1f973, roughness: 0.6, metalness: 1, reflectivity: 0.5, clearCoat: 0.52, emissive: 0x34f0f});
    }
    
    private createMaintenanceMaterial = ():THREE.MeshPhysicalMaterial => { 
        return new THREE.MeshPhysicalMaterial({color: 0xf4f901, roughness: 0.6, metalness: 1, reflectivity: 0.5, clearCoat: 0.52, emissive: 0x2d2c0c});
    }
}