// Mapping von uuid lokal -> uuid threejs scene

import ObjectUpdate from '../types/ObjectUpdate';
import SceneObject from '../types/SceneObject';
import CreateThreeObjects from './createThreeObjects';
import * as THREE from 'three';
import {Euler, Quaternion, Vector3} from 'three';
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import SpatialInformation from '../types/SpatialInformation';
import AnimMixer from '../lib/animation/animationMixer';
import Transform from '../types/Transform'


class LocalScene {
    private readonly threeJsScene: THREE.Scene;
    private hitTestObject: THREE.Mesh | null = null;
    private calibrationAnchor: THREE.Mesh | undefined;
    private isCalibrated: boolean = false;
    private animMixer: AnimMixer;

    constructor(scene: THREE.Scene, animMixer: AnimMixer) {
        this.threeJsScene = scene;
        this.addHitPointGraphic();
        //this.addAxisHelper();
        this.animMixer = animMixer;
    }

    addObject = (newThreeSceneObject: THREE.Mesh | GLTF | THREE.TextGeometry, isGltf: boolean) => {
        if (isGltf) {
            const gltf = newThreeSceneObject as GLTF;
            this.threeJsScene.add(gltf.scene);
        } else {
            const mesh = newThreeSceneObject as THREE.Mesh;
            this.threeJsScene.add(mesh);
        }
    };

    updateObject = async (objectUpdate: ObjectUpdate) => {
        console.log(`Scene.updateObject invoked for uid ${objectUpdate.id}`);
        const threeJsObjectToUpdate = this.threeJsScene.getObjectByName(objectUpdate.id);
        if (threeJsObjectToUpdate) {
            switch (objectUpdate.updateType) {
                case 'changedPosition':
                    threeJsObjectToUpdate.position.set(objectUpdate.value.x, objectUpdate.value.y, objectUpdate.value.z);
                    break;
                case 'changedRotation':
                    threeJsObjectToUpdate.rotation.set(objectUpdate.value.x, objectUpdate.value.y, objectUpdate.value.z);
                    break;
                case 'changedColor':
                    console.warn('[Scene.updateObject] changedColor not yet implemented');
                    break;
                case 'changedAnimation':
                    this.animMixer.changeAnimation(threeJsObjectToUpdate.name, objectUpdate.value)
                    console.log(`updated "${threeJsObjectToUpdate.name}". Animation "${objectUpdate.value}" started`);
                    break;
                case 'changedGLTF':
                    this.threeJsScene.remove(threeJsObjectToUpdate);
                    await this.createGLTFObject(objectUpdate.value);
                    console.log(`updated "${threeJsObjectToUpdate.name}". new Gltf "${objectUpdate.value}" was loaded`);
                    break;
                case 'changedText':
                    this.threeJsScene.remove(threeJsObjectToUpdate)
                    const newThreeSceneObject = await this.createThreeObjectFromSceneObject(objectUpdate.value) as THREE.Mesh;
                    newThreeSceneObject.name = objectUpdate.value.id;
                    if (newThreeSceneObject) {
                        this.addObject(newThreeSceneObject, objectUpdate.value.objectType === 'GLTF');
                    }
                    console.log(`updated "${threeJsObjectToUpdate.name}". Text with "${objectUpdate.value}"`);
                    break;
                default:
                    console.error(`[Scene.updateObject] No implementation for OpjectUpdate of Type "${objectUpdate.updateType}"`);
            }
        } else {
            console.error(`[Scene.updateObject] No object with id: ${objectUpdate.id} found in Scene`);
        }
    };

    initializeScene = async (sceneObjects: SceneObject[]) => {
        this.addDirectionalLight();
        await this.createObjects(sceneObjects);
    };

    createObjects = async (sceneObjects: SceneObject[] | SceneObject) => {
        if (sceneObjects instanceof Array) {
            for (const sceneObject of sceneObjects) {
                this.addSceneObjectToScene(sceneObject);
            }
        } else {
            this.addSceneObjectToScene(sceneObjects);
        }
    }

    deleteObjects = async (objectUpdate: ObjectUpdate) => {
        const threeJsObjectToUpdate = this.threeJsScene.getObjectByName(objectUpdate.id);
        if (threeJsObjectToUpdate) {
            this.threeJsScene.remove(threeJsObjectToUpdate);
        }
    };

    addSceneObjectToScene = async (sceneObject: SceneObject) => {
        let newThreeSceneObject;
        switch (sceneObject.objectType) {
            case 'GLTF':
                newThreeSceneObject = await this.createGLTFObject(sceneObject);
                break;
            case 'Text':
                newThreeSceneObject = await this.createTextObject(sceneObject);
                break;
            case 'CalibrationAnchor':
                newThreeSceneObject = await this.createCalibrationAnchorObject(sceneObject);
                break;
            default:
                newThreeSceneObject = await this.createThreeObjectFromSceneObject(sceneObject) as THREE.Mesh;
                newThreeSceneObject.name = sceneObject.id;
        }
        if (newThreeSceneObject) {
            this.addObject(newThreeSceneObject, sceneObject.objectType === 'GLTF');
        }
    };

    createGLTFObject = async (sceneObject: SceneObject): Promise<GLTF> => {
        const newThreeSceneObject = await this.createThreeObjectFromSceneObject(sceneObject) as GLTF;
        newThreeSceneObject.scene.name = sceneObject.id;
        this.animMixer.initialize(newThreeSceneObject);
        await this.animMixer.startAnimation(newThreeSceneObject.scene.name, "idle");
        return newThreeSceneObject;
    };

    createTextObject = async (sceneObject: SceneObject): Promise<THREE.Mesh> => {
        const newThreeSceneObject = await this.createThreeObjectFromSceneObject(sceneObject) as THREE.Mesh;
        newThreeSceneObject.name = sceneObject.id;
        return newThreeSceneObject;
    };

    createCalibrationAnchorObject = async (sceneObject: SceneObject): Promise<THREE.Mesh> => {
        sceneObject.spatialInformation.size = {width: 0.1, height: 0.1, depth: 0.1};
        const newThreeSceneObject = this.generateCube(sceneObject.spatialInformation, 0x00ff00);
        newThreeSceneObject.name = sceneObject.id;
        this.calibrationAnchor = newThreeSceneObject;
        return newThreeSceneObject
    };

    addDirectionalLight = () => {
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
        const hemiLight = new THREE.HemisphereLight(0x0000ff, 0xffffff, 1);

        directionalLight.castShadow = true;
        this.threeJsScene.add(hemiLight);
        this.threeJsScene.add(directionalLight);
        this.threeJsScene.add(ambientLight);
    };

    updateHitPointGraphicPosition = (transform: Transform) => {
        this.hitTestObject?.position.set(transform.position.x, transform.position.y, transform.position.z);
        //this.hitTestObject?.rotation.set(-transform.orientation.x, -transform.orientation.y, -transform.orientation.z);
    };

    updateHitPointGraphicVisibility = (visibility: boolean) => {
        if (this.hitTestObject) {
            this.hitTestObject.visible = visibility;
        }
    };

    private createThreeObjectFromSceneObject(object: SceneObject): THREE.Mesh | THREE.Scene | Promise<GLTF> | Promise<THREE.Mesh> {
        const newThreeSceneObject = CreateThreeObjects.createThreeObjectFromSceneObject(object);
        return newThreeSceneObject;
    }

    private addHitPointGraphic = () => {
        const hitPointObject = this.generateCircle(0.1,
            32,
            {
                position: {x: 0, y: 0, z: 0},
                rotation: {x:-90, y:0, z:0}
                },
            0xccff00,
            0.6,
            true);
        hitPointObject.visible = false;
        this.hitTestObject = hitPointObject;
        this.threeJsScene.add(hitPointObject);
    };

    public removeHitPointGraphic = () => {
      this.hitTestObject = null;
    };

    private generateCube = (spatialInformation: SpatialInformation, color: number) => {
        const {width, height, depth} = spatialInformation.size ? spatialInformation.size : {
            width: 0,
            height: 0,
            depth: 0
        };
        const {x, y, z} = spatialInformation?.position;
        const rotation = spatialInformation?.rotation;
        let xRot, yRot, zRot;

        if (rotation) {
            xRot = rotation.x / 180 * Math.PI;
            yRot = rotation.y / 180 * Math.PI;
            zRot = rotation.z / 180 * Math.PI;
        }

        const geometry = new THREE.BoxGeometry(width, height, depth);
        const material = new THREE.MeshBasicMaterial({color});
        const cube = new THREE.Mesh(geometry, material);
        cube.position.set(x ? x : 0, y ? y : 0, z ? z : 0);
        cube.rotation.set(xRot ? xRot : 0, yRot ? yRot : 0, zRot ? zRot : 0);

        return cube;
    };

    private generateCircle = (radius: number, segments: number, spatialInformation: SpatialInformation, color: number, opacity: number, isTransparent: boolean) => {
        const {x, y, z} = spatialInformation?.position;
        const rotation = spatialInformation?.rotation;
        let xRot, yRot, zRot;

        if (rotation) {
            xRot = rotation.x / 180 * Math.PI;
            yRot = rotation.y / 180 * Math.PI;
            zRot = rotation.z / 180 * Math.PI;
        }

        const geometry = new THREE.CircleGeometry(radius, segments);
        const material = new THREE.MeshBasicMaterial({color, opacity, transparent: isTransparent});
        const circle = new THREE.Mesh(geometry, material);


        circle.position.set(x ? x : 0, y ? y : 0, z ? z : 0);
        circle.rotation.set(xRot ? xRot : 0, yRot ? yRot : 0, zRot ? zRot : 0);

        return circle;
    };

    private addAxisHelper = () => {
        this.threeJsScene.add(new THREE.AxesHelper(5));
    };

    calibrateSceneOrigin(cameraPosition: Vector3, cameraQuaternion: Quaternion) {
        const hitPointPosition = this.hitTestObject?.position;
        const yAxis = new THREE.Vector3(0, 1, 0);
        // const zAxis = new THREE.Vector3(0, 0, 1);
        const calibrationAnchorPosition = this.calibrationAnchor?.position;
        const calibrationAnchorAngelToZAxis = this.calibrationAnchor?.rotation.y;


        let sceneOriginDelta;

        if (calibrationAnchorPosition && hitPointPosition && calibrationAnchorAngelToZAxis !== undefined) {

            /*
            *  Get the orientation of the camera in radians form the camera quaternion
            */
            const cameraEuler = new Euler().setFromQuaternion(cameraQuaternion).toArray();

            /*
             *  Make a copy of the calibration anchor position and apply the rotation of the anchor point ,
             *  and the correction for the origin and camera rotaiton offset so the calculation of the
             *  position delta is correct
             */
            const rotatedCalibrationAnchor = new Vector3().copy(calibrationAnchorPosition);
            rotatedCalibrationAnchor.applyAxisAngle(yAxis, calibrationAnchorAngelToZAxis ? -calibrationAnchorAngelToZAxis + cameraEuler[2] : 0);
            sceneOriginDelta = hitPointPosition?.sub(rotatedCalibrationAnchor);

            /*
             *  Apply corrections to the scene transformation
             */
            this.threeJsScene.translateX(sceneOriginDelta.x);
            this.threeJsScene.translateY(sceneOriginDelta.y);
            this.threeJsScene.translateZ(sceneOriginDelta.z);
            this.threeJsScene.rotateY(-calibrationAnchorAngelToZAxis + cameraEuler[2]);

            this.setIsCalibrated(true);
        }
    }

    isSceneCalibrated(): boolean {
        return this.isCalibrated;
    }

    setIsCalibrated(isCalibrated: boolean) {
        this.isCalibrated = isCalibrated;
    }
}

export default LocalScene;