import React from 'react';
import './App.css';
import GUI from "./components/gui";

function App() {
  return (
    <div className="App">
      <GUI/>
    </div>
  );
}

export default App;
