import React, {useEffect, useState} from "react";
import XrRenderer from "../../webXR";
import ControlledButton from "./button";
import './gui.css'


const GUI: React.FC = () => {
    const [session, setSession] = useState(null);
    const [webXeRenderer, setWebXeRenderer] = useState<XrRenderer | null>(null);
    const [isImmersiveArSessionSupported, setIsImmersiveArSessionSupported] = useState(false);
    const [isSceneCalibrated, setIsSceneCalibrated] = useState(false);
    const [isCalibrationReady, setIsCalibrationReady] = useState(false);
    const [messageContent, setMessageContent] = useState("");

    useEffect(() => {
        initializeWebXrRenderer();

        const checkAndSetSessionSupport = async () => {
            setIsImmersiveArSessionSupported(await XrRenderer.checkIsImmersiveArSessionSuported());
        };
        checkAndSetSessionSupport();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!session) {
            setMessageContent('Please move to the rooms calibration point, ' +
                'then press the button below to start the AR session');
        }

        if (!isImmersiveArSessionSupported) {
            setMessageContent('An immersive AR session is not supported by your device/browser');
        }

        if (session && !isSceneCalibrated) {
            setMessageContent('Now wee need to calibrate the scene. \n' +
                'If you are not yet there, move to the rooms calibration point. \n' +
                'Align the virtual element with the rooms calibration point and press "Calibrate"');
        }

        if (session && isSceneCalibrated) {
            setMessageContent('');
        }

    }, [isSceneCalibrated, isImmersiveArSessionSupported, session]);


    const onEnterArPressed = async () => {
        if (webXeRenderer) {
            if (!session) {
                const session = await webXeRenderer.startSession();
                setSession(session);
            } else {
                webXeRenderer.stopSession(session);
                setWebXeRenderer(null);
                setSession(null);
                setIsSceneCalibrated(false);
                setIsCalibrationReady(false);
                initializeWebXrRenderer();
            }
        }
    };

    const onCalibratePressed = async () => {
        if (webXeRenderer) {
            if (!isSceneCalibrated) {
                await webXeRenderer.calibrateScene();
                setIsSceneCalibrated(true);
            } else {
                webXeRenderer.recalibrateScene(session);
                setIsSceneCalibrated(false);
            }
        }
    };

    const onCalibrationReady = (isCalibrationReady: boolean) => {
        setIsCalibrationReady(isCalibrationReady);
    };

    const onSessionEnded = () => {
        setWebXeRenderer(null);
        setSession(null);
        setIsSceneCalibrated(false);
        setIsCalibrationReady(false);
        initializeWebXrRenderer();
    };

    const initializeWebXrRenderer = () => {
        const xrRenderer = new XrRenderer();
        xrRenderer.setIsCallibratedCallback(onCalibrationReady);
        xrRenderer.setOnSessionEndedCallback(onSessionEnded)
        setWebXeRenderer(xrRenderer);
    };

    return (
        <div className="guiContent">
            <div className="message">
                <div className="messageContent">{messageContent}</div>
            </div>
            <div className="xrControlButtons">
                <ControlledButton
                    isDisabled={!isImmersiveArSessionSupported}
                    onClick={onEnterArPressed}
                >
                    {!session ? 'Enter AR' : 'Exit AR'}
                </ControlledButton>
                {session ? <div className="buttonSpacer"></div> : <></>}
                <ControlledButton
                    isVisible={session ? true : false}
                    isDisabled={!isCalibrationReady}
                    onClick={onCalibratePressed}
                >
                    {!isSceneCalibrated ? 'Calibrate' : 'Recalibrate'}
                </ControlledButton>
            </div>
        </div>
    )
};

export default GUI