import * as THREE from 'three';
import LocalScene from '../scene/LocalScene'
import {Camera, Clock, Quaternion, Scene, WebGLRenderer} from 'three';
import AnimMixer from '../lib/animation/animationMixer';
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!../webworker/worker";



class XrRenderer {
  // XR globals.
  private xrRefSpace: any;
  private xrHitTestSource: any;
  private viewerRefSpace: any;
  private hitPointPosition: any;
  private THREEjsScene: Scene;
  private AnimationMixer: AnimMixer;
  private clock: Clock;
  private localScene: LocalScene;
  private readonly THREEjsRenderer: WebGLRenderer;
  private readonly THREEjsGL: WebGLRenderingContext;
  private readonly THREEjsCamera: Camera;
  private worker: Worker;
  private isSceneCalibrated = false;
  private isCalibrationReadyCallback: (isCalibrationReady: boolean) => void;
  private onSessionEndedCallback: () => void;

  constructor() {
    // THREE.js
    this.THREEjsScene = new THREE.Scene();
    this.AnimationMixer = new AnimMixer();
    this.clock = new THREE.Clock();

    this.THREEjsRenderer = new THREE.WebGLRenderer({
      alpha: true,
      preserveDrawingBuffer: true
    });
    this.THREEjsGL = this.THREEjsRenderer.getContext();
    this.THREEjsCamera = new THREE.PerspectiveCamera();
    this.localScene = new LocalScene(this.THREEjsScene, this.AnimationMixer);
    this.worker = new Worker();
    this.isCalibrationReadyCallback = () => { console.error("No calibration ready callback set")};
    this.onSessionEndedCallback = () => { console.error("No onSessionEnded callback set")};

    this.initWebWorker();
  }

  initializeThreeComponents = () => {
    this.THREEjsScene = new THREE.Scene();
    this.AnimationMixer = new AnimMixer();
    this.clock = new THREE.Clock();
    this.localScene = new LocalScene(this.THREEjsScene, this.AnimationMixer);
  };

  startSession = async () => {
    // eslint-disable-next-line no-undef
    // @ts-ignore
    const session = await navigator.xr.requestSession('immersive-ar' ,
      {
        requiredFeatures: ['local', 'hit-test'],
        optionalFeatures: ['dom-overlay'],
        domOverlay: {root: document.body}
      });
    await this.onSessionStarted(session);
    return session;
  };

  private onSessionStarted = async (session: any) => {
    try {
      this.initializeThreeComponents();
      this.worker.postMessage('ready');
      this.worker.postMessage('initialize');

      //session.addEventListener('select', this.onSelect);
      session.addEventListener('end', this.onSessionEnded);

      this.THREEjsRenderer.autoClear = false;
      this.THREEjsRenderer.shadowMap.enabled = true;
      this.THREEjsRenderer.shadowMap.type = THREE.PCFSoftShadowMap;

      // @ts-ignore
      await this.THREEjsGL.makeXRCompatible();

      // @ts-ignore
      session.updateRenderState({ baseLayer: new XRWebGLLayer(session, this.THREEjsGL) });
      session.updateRenderState({
        depthNear: 0.001,
        depthFar: 200000.0
      });

      /*
       * Hit-Test
       */
      this.viewerRefSpace = await session.requestReferenceSpace('viewer');
      this.xrHitTestSource = await session.requestHitTestSource({space: this.viewerRefSpace});

      this.xrRefSpace = await session.requestReferenceSpace('local');
      this.THREEjsCamera.matrixAutoUpdate = false;

      session.requestAnimationFrame(this.onXRFrame);
    } catch (e) {
      console.error(e);
    }
  };

  stopSession = (session: any) => {
    console.log("stopSession invoked");
    session.end();
    this.THREEjsGL?.bindFramebuffer(
      this.THREEjsGL.FRAMEBUFFER,
      null
      );
    session = null;
  };

  onSessionEnded = () => {
    console.log("onEndSession invoked");
    this.onSessionEndedCallback();
  };

  calibrateScene = () => {
    console.log("onCalibrate invoked");
    if (this.hitPointPosition && !this.localScene.isSceneCalibrated() && this.THREEjsCamera) {
      const cameraPosition = this.THREEjsCamera.getWorldPosition(new THREE.Vector3(this.hitPointPosition.x));
      const cameraQuaternion = new Quaternion();
      this.THREEjsCamera.getWorldQuaternion(cameraQuaternion);
      this.localScene.calibrateSceneOrigin(cameraPosition, cameraQuaternion);
      this.isSceneCalibrated = true;
    }
  };

  // Called every time a XRSession requests that a new frame be drawn.
  private onXRFrame = (t: any, frame: any) => {
    const { session } = frame;
    const pose = frame.getViewerPose(this.xrRefSpace);
    this.THREEjsGL?.bindFramebuffer(
      this.THREEjsGL.FRAMEBUFFER,
      session.renderState.baseLayer.framebuffer
    );
    // @ts-ignore
    this.THREEjsRenderer.setFramebuffer(session.renderState.baseLayer.framebuffer);

    if (pose && this.THREEjsCamera && this.THREEjsRenderer) {
      const mixers = this.AnimationMixer.getMixerInfoArray();
      let delta = this.clock.getDelta();


      pose.views.forEach((view: any) => {

        if(mixers) {
          mixers.forEach(animationMixer => {
            animationMixer.mixer.update(delta);
          });
        }
        const viewport = session.renderState.baseLayer.getViewport(view);

        /*
         * Hit-Test
         */
        if(this.xrHitTestSource && !this.localScene.isSceneCalibrated()) {
          const hitTestResults = frame.getHitTestResults(this.xrHitTestSource);
          this.hitPointPosition = null;

          if (hitTestResults.length > 0) {
            this.hitPointPosition = hitTestResults[0].getPose(this.xrRefSpace).transform.position;
            //console.log('hit point transform:', hitTestResults[0].getPose(this.xrRefSpace).transform);
            this.localScene.updateHitPointGraphicPosition(hitTestResults[0].getPose(this.xrRefSpace).transform);
            this.localScene.updateHitPointGraphicVisibility(true);
            this.isCalibrationReadyCallback(true);
          } else {
            this.isCalibrationReadyCallback(false);
            this.localScene.updateHitPointGraphicVisibility(false);
          }
        }

        this.THREEjsRenderer.setSize(viewport.width, viewport.height);

        // Set the view matrix and projection matrix from XRDevicePose
        // and XRView onto our THREE.Camera.
        this.THREEjsCamera.projectionMatrix.fromArray(view.projectionMatrix);
        const viewMatrix = new THREE.Matrix4().fromArray(view.transform.inverse.matrix);

        this.THREEjsCamera.matrix.getInverse(viewMatrix);
        this.THREEjsCamera.updateMatrixWorld(true);

        // Render our scene with our THREE.WebGLRenderer
        this.THREEjsRenderer.render(this.THREEjsScene, this.THREEjsCamera);
      });
    }
    session.requestAnimationFrame(this.onXRFrame);
  };


  recalibrateScene = (session: any) => {
    this.onSessionStarted(session);
  };

  private initWebWorker = () => {
    this.worker.onmessage = msg => {
      if (msg.data) {
        switch (msg.data.event) {
          case 'initializeScene':
            console.log('[initalizeSzene] received data');
            console.log(msg.data);
            this.localScene.initializeScene(msg.data.objects);
            break;
          case 'changedPosition':
            console.log('"changePosition" invoked');
            this.localScene.updateObject(msg.data.objects);
            break;
          case 'changedSomethingElse':
            console.log('"changedSomethingElse" invoked');
            break;
          case 'changedRotation':
            console.log('"changeRotation" invoked');
            this.localScene.updateObject(msg.data.objects);
            break;
          case 'updateAnimation':
            console.log("update animation");
            this.localScene.updateObject(msg.data.objects);
            break;
          case 'changedText': 
            console.log('"changedText"');
            this.localScene.updateObject(msg.data.objects);
            break;
          case 'changedGLTF':
            console.log('"changedGLTF"');
            this.localScene.updateObject(msg.data.objects);
            break;
          case 'createSceneObject':
            this.localScene.createObjects(msg.data.objects.value);
            break;
          case 'deletedObject':
            this.localScene.deleteObjects(msg.data.objects);
            break;            
          default:
            console.error(
              `No implementation present for message of type "${msg.data}" in onmessage method`
            );
        }
      }
    };
  };

  setIsCallibratedCallback = (callback: (isCalibrationReady: boolean) => void) => {
    this.isCalibrationReadyCallback = callback
  };

  setOnSessionEndedCallback = (callback: () => void) => {
    this.onSessionEndedCallback = callback;
  };

  static checkIsImmersiveArSessionSuported = async (): Promise<boolean> => {
    // @ts-ignore
    if (navigator.xr) {
      // @ts-ignore
      if (await navigator.xr.isSessionSupported('immersive-ar')) {
        // @ts-ignore
        return true;
      }
    }
    return false
  };
}

export default XrRenderer
