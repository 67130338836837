import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// @ts-ignore
// import WebXRPolyfill from 'webxr-polyfill';

//const polyfill = new WebXRPolyfill();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);